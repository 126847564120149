<script setup>
// import Tempus from '@darkroom.engineering/tempus';
import Swiper from 'swiper'
import { Navigation, FreeMode } from 'swiper/modules'
import ArrowLine from '~/assets/icons/arrow-line.svg'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/free-mode'

const { activeSite } = useSharedState()

const slider = ref(null)
const prev = ref(null)
const next = ref(null)
const root = ref(null)
// const rafFrame = ref(0);
// const progress = ref(0);
// const speed = ref(0);
// const initialProgress = ref(0);
const swiperInstance = ref(null)

const props = defineProps({
    data: {
        type: Object,
        default: () => ({}),
    },
})

// const makeProgress = () => {
//     if (swiperInstance.value.enabled) {
//         progress.value += speed.value;
//         if (progress.value >= 1) {
//             progress.value = initialProgress.value;
//         }
//         swiperInstance.value.setProgress(progress.value, 0);
//         // rafFrame.value = window.requestAnimationFrame(makeProgress);
//     }
// };

onMounted(() => {
    swiperInstance.value = new Swiper(slider.value, {
        modules: [Navigation, FreeMode],
        navigation: {
            prevEl: prev.value,
            nextEl: next.value,
        },
        // loop: true,
        speed: 700,
        enabled: false,
        breakpoints: {
            1024: {
                // loop: true,
                // loopAdditionalSlides: 0,
                speed: 700,
                // centeredSlides: true,
                enabled: true,
                slidesPerView: 'auto',
                spaceBetween: 36,

                freeMode: {
                    enabled: true,
                    sticky: true,
                },
            },
        },
        // on: {
        //     init: (instance) => {
        //         initialProgress.value = instance.progress;
        //         progress.value = instance.progress;
        //         speed.value = 0.0002;
        //     },
        // },
    })

    // if (!isServer()) {
    //     Tempus.add(makeProgress, 3);
    // }
})

onBeforeUnmount(() => {
    // progress.value = initialProgress.value;
    // window.cancelAnimationFrame(rafFrame.value);
    swiperInstance.value.off('sliderFirstMove')
    swiperInstance.value.off('touchStart')
})

const { data: newsData } = await useAsyncGql({
    operation: 'FetchSliderNews',
    variables: { lang: activeSite.value.lang, count: props.data.count },
})
</script>

<template>
    <div
        ref="root"
        class="bloc_list_news overflow-hidden"
    >
        <div class="pb-24 pt-22 lg:py-12">
            <div
                v-if="data?.sectionTitle || data?.subtitle"
                class="px-12 xl:px-23"
            >
                <h2
                    v-if="data?.sectionTitle"
                    class="text-4xl lg:text-6xl"
                    :class="{ 'mb-8 lg:mb-13': !data?.subtitle }"
                >
                    <ClientOnly>
                        <SplitText>
                            <div v-html="parseMarkdown(data.sectionTitle)" />
                        </SplitText>
                        <template #fallback>
                            <div v-html="parseMarkdown(data.sectionTitle)" />
                        </template>
                    </ClientOnly>
                </h2>
                <div
                    v-if="data?.sectionTitle && data?.subtitle"
                    class="mb-4 mt-2 block h-[2px] w-20 bg-current"
                />
                <h3
                    v-if="data?.subtitle"
                    class="mb-13 max-w-xs text-xl uppercase lg:text-sm"
                >
                    <Parallax
                        :animation="false"
                        :leave="false"
                    >
                        <div v-html="parseMarkdown(data.subtitle)" />
                    </Parallax>
                </h3>
            </div>
            <div class="slider-wrapper px-4 xl:px-23">
                <div
                    ref="slider"
                    class="swiper !overflow-visible"
                >
                    <div class="swiper-wrapper flex flex-col gap-12 !overflow-visible lg:flex-row lg:gap-0">
                        <PartialsArticleCard
                            v-for="(article, newsIndex) in newsData.entries"
                            :key="'news' + newsIndex"
                            class="swiper-slide relative mb-8 !h-auto w-[32%] rounded-4xl px-4 pb-16 pt-4 shadow-xl lg:mb-0 lg:pb-4 xl:p-7"
                            :data-cust-slide-index="newsIndex"
                            :article="article"
                        />
                    </div>

                    <div
                        ref="prev"
                        class="absolute left-0 top-1/2 z-10 flex size-12 -translate-x-1/2 cursor-pointer items-center justify-center rounded-full bg-gold text-white transition"
                    >
                        <ArrowLine class="-rotate-45 -scale-x-100 fill-current" />
                    </div>
                    <div
                        ref="next"
                        class="absolute right-0 top-1/2 z-10 flex size-12 translate-x-1/2 cursor-pointer items-center justify-center rounded-full bg-gold text-white transition"
                    >
                        <ArrowLine class="rotate-45 fill-current" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="postcss">
.swiper-button-disabled {
    @apply opacity-0;
}

.swiper-slide {
    width: calc(27.5% - (36px / 2));
    /* transition-property: width; */
    /* transition-duration: 700ms; */
}

/* .swiper-slide.swiper-slide-active{
        width: calc(45% - 36px);
    } */
.swiper-slide[data-cust-slide-index="0"] {
    width: calc(45% - 36px);
}

/* .swiper-slide.swiper-slide-active .excerpt{
        @apply opacity-100;
    } */
.swiper-slide[data-cust-slide-index="0"] .excerpt {
    @apply opacity-100;
}
</style>
